exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-basic-js": () => import("./../../../src/templates/basic.js" /* webpackChunkName: "component---src-templates-basic-js" */),
  "component---src-templates-beperking-js": () => import("./../../../src/templates/beperking.js" /* webpackChunkName: "component---src-templates-beperking-js" */),
  "component---src-templates-beperking-overzicht-js": () => import("./../../../src/templates/beperking-overzicht.js" /* webpackChunkName: "component---src-templates-beperking-overzicht-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-form-feedback-js": () => import("./../../../src/templates/form-feedback.js" /* webpackChunkName: "component---src-templates-form-feedback-js" */),
  "component---src-templates-form-js": () => import("./../../../src/templates/form.js" /* webpackChunkName: "component---src-templates-form-js" */),
  "component---src-templates-spel-js": () => import("./../../../src/templates/spel.js" /* webpackChunkName: "component---src-templates-spel-js" */),
  "component---src-templates-spel-overzicht-js": () => import("./../../../src/templates/spel-overzicht.js" /* webpackChunkName: "component---src-templates-spel-overzicht-js" */)
}

